import React, { useEffect } from "react";
import PropTypes from "prop-types";

const Demo = ({ url, fileName, clientId }) => {
  useEffect(() => {
    // Load the Adobe View SDK script
    const script = document.createElement("script");
    script.src = "https://acrobatservices.adobe.com/view-sdk/viewer.js";
    script.async = true;
    script.onload = () => {
      // Wait for the Adobe View SDK to be ready
      document.addEventListener("adobe_dc_view_sdk.ready", () => {
        const adobeDCView = new window.AdobeDC.View({
          clientId,
          divId: "adobe-dc-view",
        });
        adobeDCView.previewFile(
          {
            content: { location: { url } },
            metaData: { fileName },
          },
          {
            defaultViewMode: "FIT_WIDTH",
            showAnnotationTools: false,
            showDownloadPDF: false,
            showPrintPDF: false,
          }
        );
      });
    };
    document.body.appendChild(script);

    // Clean up the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, [url, fileName, clientId]);

  return (
    <div id="adobe-dc-view" style={{ width: "100%", height: "100vh" }}></div>
  );
};

Demo.propTypes = {
  url: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  clientId: PropTypes.string.isRequired,
};

export default Demo;
