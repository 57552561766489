import React, { useState } from "react";
import { Carousel as BootstrapCarousel } from "react-bootstrap";

function BookCarousel() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const goToPrev = () => {
    if (index > 0) {
      setIndex(index - 1);
    } else {
      setIndex(2);
    }
  };

  const goToNext = () => {
    if (index < 2) {
      setIndex(index + 1);
    } else {
      setIndex(0);
    }
  };

  return (
    <div>
      <BootstrapCarousel
        activeIndex={index}
        onSelect={handleSelect}
        interval={3000}
      >
        {/* Set the interval to 3 seconds */}
        <BootstrapCarousel.Item>
          <img
            className="d-block w-100"
            src="https://t3.ftcdn.net/jpg/06/43/94/52/360_F_643945254_Ov7nW6251qBSakE3BSyH1SljAONlJ0KA.jpg"
            alt="First slide"
            style={{ maxHeight: "300px", objectFit: "cover" }}
          />
          <BootstrapCarousel.Caption className="text-center">
            <h2>Unlock Infinite Worlds: Explore Athmiya eBooks</h2>
            <p>
              Vast, immersive digital library awaits. Journey through endless
              inspiration today.
            </p>
            <button className="btn btn-primary">About Aathmiya</button>
          </BootstrapCarousel.Caption>
        </BootstrapCarousel.Item>
        <BootstrapCarousel.Item>
          <img
            className="d-block w-100"
            src="https://miro.medium.com/v2/resize:fit:1200/1*X7mmDkgN--jS7b_dUsgVmg.jpeg"
            alt="Second slide"
            style={{ maxHeight: "300px", objectFit: "cover" }}
          />
          <BootstrapCarousel.Caption className="text-center">
            <h2>Empower Your Reading Experience with Athmiya eBooks</h2>
            <p>
              Modernize reading. Accessible, convenient, immersive. Elevate your
              literary journey now.
            </p>
            <button className="btn btn-primary">About Aathmiya</button>
          </BootstrapCarousel.Caption>
        </BootstrapCarousel.Item>
      </BootstrapCarousel>
    </div>
  );
}

export default BookCarousel;
