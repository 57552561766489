import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./components/home";
import LoginSignup from "./components/loginsignup";
import About from "./components/about";
import React from "react";
import ReactDOM from "react-dom";
import Demo from "./components/demo";
import ViewBook from "./components/viewbook";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<Home></Home>}></Route>
          <Route path="/home" element={<Home></Home>}></Route>
          <Route path="/about" element={<About></About>}></Route>
          <Route path="/demo" element={<Demo></Demo>}></Route>
          <Route path="/viewbook" element={<ViewBook></ViewBook>}></Route>

          <Route
            path="/loginsignup"
            element={<LoginSignup></LoginSignup>}
          ></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));
export default App;
