import React, { useState } from "react";
import Navbar from "./navbar";
import Footer from "./footer";
import Book from "./book";
import BookCarousel from "./carousel";

const books = [
  {
    title: "Inthi Nimma Aathmiya",
    imageSrc: "https://i.ibb.co/qrz6cWM/img1.png",
    description: "Description for Book 1",
  },
  {
    title: "Book 2",
    imageSrc: "https://i.ibb.co/qrz6cWM/img1.png",
    description: "Description for Book 2",
  },
  {
    title: "Book 3",
    imageSrc: "https://i.ibb.co/qrz6cWM/img1.png",
    description: "Description for Book 3",
  },
  {
    title: "Book 4",
    imageSrc: "https://i.ibb.co/qrz6cWM/img1.png",
    description: "Description for Book 4",
  },
];
function Home() {
  const [bookList, setBookList] = useState();

  useState(() => {
    setBookList(books);
  }, []);

  const handleSearch = (searchQuery) => {
    const lowercasedQuery = searchQuery.toLowerCase();
    let updatedList = books?.filter(
      (book) =>
        book.title.toLowerCase().includes(lowercasedQuery) ||
        book.description.toLowerCase().includes(lowercasedQuery)
    );
    setBookList(updatedList);
  };
  return (
    <div>
      <Navbar handleSearch={handleSearch}></Navbar>
      <BookCarousel></BookCarousel>
      <Book books={bookList}></Book>
      {/* <Footer></Footer> */}
    </div>
  );
}

export default Home;
