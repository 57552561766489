import React, { useState } from "react";

const LoginSignup = () => {
  const [isSignup, setIsSignup] = useState(false);

  const toggleForm = () => {
    setIsSignup(!isSignup);
  };

  return (
    <section>
      <div className="container">
        <div className={`user ${isSignup ? "signupBx" : "signinBx"}`}>
          <div className="imgBx">
            <img src="https://i.ibb.co/qrz6cWM/img1.png" alt="" />
          </div>
          <div className="formBx">
            <form action="" onSubmit={(e) => e.preventDefault()}>
              <h2>{isSignup ? "Create an account" : "Sign In"}</h2>
              <input type="text" name="" placeholder="Username" />
              {!isSignup && (
                <input type="password" name="" placeholder="Password" />
              )}
              <input
                type="submit"
                name=""
                value={isSignup ? "Sign Up" : "Login"}
              />
              <p className="signup">
                {isSignup
                  ? "Already have an account ?"
                  : "Don't have an account ?"}{" "}
                <a href="#" onClick={toggleForm}>
                  {isSignup ? "Sign in." : "Sign Up."}
                </a>
              </p>
            </form>
          </div>
        </div>
        <div className={`user ${isSignup ? "signinBx" : "signupBx"}`}>
          <div className="formBx">
            <form action="" onSubmit={(e) => e.preventDefault()}>
              <h2>{isSignup ? "Sign In" : "Create an account"}</h2>
              <input type="text" name="" placeholder="Username" />
              {isSignup && (
                <input type="email" name="" placeholder="Email Address" />
              )}
              <input type="password" name="" placeholder="Create Password" />
              {isSignup && (
                <input type="password" name="" placeholder="Confirm Password" />
              )}
              <input
                type="submit"
                name=""
                value={isSignup ? "Login" : "Sign Up"}
              />
              <p className="signup">
                {isSignup
                  ? "Don't have an account ?"
                  : "Already have an account ?"}{" "}
                <a href="#" onClick={toggleForm}>
                  {isSignup ? "Sign Up." : "Sign in."}
                </a>
              </p>
            </form>
          </div>
          <div className="imgBx">
            <img src="https://i.ibb.co/qrz6cWM/img1.png" alt="" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default LoginSignup;
