import React, { useState } from "react";
import { Link, Navigate } from "react-router-dom"; // If you're using React Router

function Navbar(props) {
  const { handleSearch } = props;

  const [searchItem, setSearchItem] = useState();
  const [searchQuery, setSearchQuery] = useState("");

  const onclickLogo = () => {
    Navigate("/home");
  };
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container">
        <div className="logo" onClick={onclickLogo}>
          <img
            className="logo"
            onClick={onclickLogo}
            src={require("../assets/images/Logotransparent.png")}
            alt="Logo"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </div>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ms-auto">
            <form className="d-flex">
              <div className="input-group">
                <input
                  className="form-control"
                  type="search"
                  placeholder="&#x1F50D; Search Books Here"
                  aria-label="Search"
                  onChange={(e) => handleSearch(e.target.value)}
                />
              </div>
            </form>
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link className="nav-link" to="/profile">
                  <i className="bi bi-person-fill"></i> Profile
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/books">
                  <i className="bi bi-book-fill"></i> About
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/settings">
                  <i className="bi bi-gear-fill"></i> Settings
                </Link>
              </li>
            </ul>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
