import React from "react";
import Demo from "./demo";

const ViewBook = () => {
  return (
    <div>
      <h1>Aathmiya eBook Test PDF</h1>
      <Demo
        url="https://aathmiya.s3.ap-south-1.amazonaws.com/aathmiya.pdf?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEBMaCmFwLXNvdXRoLTEiRzBFAiEA99CsqTq3MIFjoHbS7Wm9M2fvcbsfx8EDEXgxlM%2BypWICIBbNCWpxd4VzfqIyoP3efpXFQvFPjpVkT0V%2B%2BB2IbtcNKu0CCIz%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQABoMOTc1MDUwMDM3NzYyIgy%2FnOo4AyahegM8tksqwQJ%2FITWOnsJ6eR215MfZW%2B5PkqpyOZ5zgrzvG%2BnmdzSRrQjSs4FwH5qiluSPAxQqpXlgvWDsZFPKrWJ%2F1znuNg9YAjeGXro0v3L90I9q1oE8S6fqkVFrIHgy7vOFyP1dKjO%2BmgebZhiShL%2F%2FXjXX3z%2FgNM6ZBwB6NRAFf3lymleciFjsQz5Aa%2FUUzlE%2Bho%2FTmGDSYIcKfqQ8i0mgns81qcU88Lsg5bWr%2Ft3YspV5i%2BXTAJqTmOe2KS48dtDe3e%2BOForq8Yh1Qqd2LP9HdwFddOaSEy2rJ3K%2F%2FpUmUISMYtqL0W%2F0fOosTu3lKrRV%2BvG7wPsokoTtwzjZYyvT1x3B6TkKsjgv%2F2BQmA9UYJ%2F3TZ%2FoI0InJ7lQbWTOkkQYZHPgGAR%2Frd8ybtRQvZbud20l7NCm6GrhvgOjMYkqbpmbt69WFwow%2FNXBsgY6swJVeOSVD27bJKRQScGfkk4lrZYwn0OB62MUGPA39PsViA2O0zNaOTu4QeH5B1tkqAOqI%2BHmVWU6T8XLmPZ%2Biy%2BdFGIm30%2B%2F7tCPsFQfwacZB4XtQV6XgxeB4MfYBPbaZnLL56VJcXBVzpIXZbvedSjOJDL359Q93cKn3wyGRaL8OGJ3V5zJRNbFjgM1PY2skvFIlk1N92X0xAtzGhVWOH7U42IduMHGyVT0WBqLjhJCtApY9PJAKNDLuQ9oDDivZrplgbt2AMNipKxRJPySLUyITBmVdYldKCwubE%2Byu8qp1zTehJkl5X3BMnBGA9D1K98rHyhHGwMZMKI%2FdXHfN3oUXSBKgxj3sOKn1t9ZM6h%2FSofDremqGNinGeS%2FyyuvqZWO054ocFQZrvK9DeuWy08%2FaAo5&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20240524T105427Z&X-Amz-SignedHeaders=host&X-Amz-Expires=43200&X-Amz-Credential=ASIA6GBMC3IBADINGKV6%2F20240524%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Signature=7e63ca21bb81dc9716c24d40b084c43f65959cf7abfce0f6df7c1d95b9b6951b"
        fileName="Aathmiya eBook"
        clientId="c59cc8b16daf4968b1c93ce917a1de6f"
      />
    </div>
  );
};

export default ViewBook;
