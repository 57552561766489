import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

function Book(props) {
  const { books } = props;
  const navigate = useNavigate();

  function handleRead() {
    navigate("/viewbook");
  }

  return (
    <div className="container py-3">
      <h1 className="one">Aathmiya Books</h1>
      <hr />
      <div className="main-box-container">
        {books?.length === 0 ? (
          <div>No results found</div>
        ) : (
          books?.map((book, index) => (
            <div className="box-container" key={index}>
              <img src={book.imageSrc} alt={book.title} />
              <h3>{book.title}</h3>
              <p>{book.description}</p>
              <button className="cursor-pointer" onClick={handleRead}>
                Read Now
              </button>
            </div>
          ))
        )}
      </div>
    </div>
  );
}

export default Book;
